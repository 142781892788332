/**

  Thinking Mobile-First!

  Write your "standard" CSS-code for the smallest resolution (width 320px). Its valid for all resolutions.
  If you need a variant for a higher resolution use the from-width() mixin. The variant is valid for the size specified in the parameter - and above!
  If you need an other variant for a more higher resolution use the from-width() mixin again.

  from-width($size) - allowed values for $size: $s, $m, $l, $xl


  EXAMPLE FOR USE:
  ================

  .foo {
    color: chocolate;               <-- valid for all resolutions
    font-size: 12px;                <-- valid up to 767px;
    margin-bottom: 10px;            <-- valid up to 1279px;

    @include from-width($m) {
      font-size: 14px;              <-- valid from 768px up to 1279px
    }

    @include from-width($xl) {
      font-size: 16px;              <-- valid from 1280px and above
      margin-bottom: 20px;          <-- valid from 1280px and above
    }
  }

 */


// FROM - BREAKPOINT SETTINGS
$xs:    320;      // Phone
$s:     480;      // Phablet
$m:     768;      // Mini-Tablet
$l:     1024;     // Tablet
$xl:    1280;     // Deskop


@mixin from-width($size) {
  $minBreakpoint: $size;
  @media only screen and (min-width: #{$minBreakpoint}px) { @content; }
}
