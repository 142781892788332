// FIGMA color scheme

:root {
  --alert: #ff5050;
  --available-coach: #cce5cc;
  --yellow: #ffe8a3;
  --black: #454542;
  --blue: #2373e9;
  --booked-coach: #008000;
  --booked-first: #008000;
  --conflict: #ff0000;
  --dark-grey: #aaaaaa;
  --darker-grey: #6f6f6f;
  --light-blue: #e6f4ff;
  --light-green: #cce5cc;
  --light-grey: #eeeeee;
  --light-orange: #ffead8;
  --light-red: #ffdcdc;
  --mid-blue: #87a8ff;
  --mid-dark-grey: #bbbbbb;
  --mid-light-grey: #dddddd;
  --mid-grey: #cccccc;
  --not-available-outlook: #ffc7c2;
  --not-available: #ffc7c2;
  --orange: #ff9754;
  --reserved-first: #ffa629;
}
