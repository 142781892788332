.button {
  margin-right: 30px;
  border-radius: 3px;
  background-color: var(--mid-grey);
  padding: 5px 10px;

  & {
    @include fontTextM;
  }

  &--active {
    background-color: var(--black);
    color: white;
  }
}
