/*
These styles override the default styles provided by Angular Material v17.
With upgrades to Angular Material v18 or higher, changes may be necessary.
*/

button.mdc-icon-button.mat-mdc-icon-button.cdk-focused > svg {
  fill: var(--blue);
}

/* date input style*/
.mat-mdc-input-element.mat-datepicker-input.mat-mdc-form-field-input-control.mdc-text-field__input {
  color: var(--black);
  font-weight: 500;
}

/* calendar picker wrapper*/
.mat-datepicker-content {
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
}

/* calendar picker header*/
.mat-calendar-header {
  background-color: var(--light-grey);
}

/* input icon button */
button.mdc-icon-button.mat-mdc-icon-button.mat-unthemed.mat-mdc-button-base {
  padding: 0;
}

/* calendar picker - onCancel button */
button.mat-datepicker-close-button.mdc-button.mdc-button--raised.mat-mdc-raised-button {
  box-shadow: 0 0 0 2px rgba(10, 109, 255, 0.3);
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: white;
}

/* calendar picker - day cell */
span.mat-calendar-body-cell-content.mat-focus-indicator {
  border-color: transparent;
}

/* calendar picker - highlighting of current day, month, year */
span.mat-calendar-body-today:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  box-shadow: 0 0 0 2px rgb(255, 126, 87, 0.5);
  border-color: rgb(255, 126, 87);
}

/* calendar picker - calendar datepicker highlighting of selected day */
span.mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-selected {
  border-color: var(--blue);
  background-color: var(--blue);
  color: white;
}

/* calendar datepicker highlighting of focused day */
button.mat-calendar-body-active > span.mat-calendar-body-cell-content.mat-focus-indicator {
  box-shadow: 0 0 0 2px rgba(10, 109, 255, 0.3);
  border-color: var(--blue);
}

/* calendar datepicker highlighting of hovered day */
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  box-shadow: 0 0 0 2px rgba(10, 109, 255, 0.3);
  border-color: var(--blue);
}

/* input date icon - date picker closed */
.mdc-icon-button > svg {
  fill: #a2a2a2;
}

/* input date icon - date picker opened */
.mat-datepicker-toggle-active > .mdc-icon-button > svg {
  fill: var(--blue);
}

/* input date icon - size and position */
.mat-mdc-icon-button.mat-mdc-button-base {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.mdc-text-field--filled.mdc-text-field--disabled {
  background-color: var(--light-grey);
}
