/**

  Use follow mixins to use the grid in your components:
  1.  grid-root-container([$rows]);
  2.  grid-sub-container($cols [, $rows]);
  3.  grid-item($start, $end [, $isInSubContainer]);
  3a. grid-item-ie-sideedge-desktop($start, $end);
  4.  grid-item-rows($start [, $end]);


  DETAILS:
  ========

  1. grid-root-container([$rows]);
  Creates a grid with 12 main-columns and 11 gap-columns for distances between these main-columns. For mobile resolutions its 6 main-columns and 5 gap-columns.
  In addition there are two columns on the left and right for the distance to the edge of the page.

  parameters:
    $rows - number (optional, default: 1)



  2. grid-sub-container($cols [, $rows]);
  Creates a grid with <$cols> main-columns and <$cols - 1> gap-columns for distances between these main-columns.
  Different to grid-root-container() there are no additional columns for distances to the edge of the page.

  parameters:
    $cols - number
    $rows - number (optional, default: 1)



  3. grid-item($start, $end [, $isInSubContainer])
  Positions the item in the columns.
  If you want to use 'sideedgeLeft' or 'sideedgeRight' in a resolution bigger than $m, call 'grid-item-ie-sideedge-desktop' additionally for IE-support!

  parameters:
    $start - first column for this item. allowed values are numbers and sideedgeLeft.
    $end - last column for this item. allowed values are numbers and sideedgeRight.
    $isInSubContainer - true, if it an item in a sub-container (optional, default: false)



  3a. grid-item-ie-sideedge-desktop($start, $end)
  Workaround for legacy grid-API. No longer necessary when IE-support expires.
  Use it additional to 'grid-item', if you want to use 'sideedgeLeft' or 'sideedgeRight' in a resolution bigger than $m.
  Use it always in a $m (or higher) media-query:

  @include from-with($m) {
    @include grid-item-ie-sideedge-desktop(sideedgeLeft, sideedgeRight);
  }

  parameters:
    $start - first column for this item. allowed values are numbers and sideedgeLeft.
    $end - last column for this item. allowed values are numbers and sideedgeRight.



  4. grid-item-rows($start [, $end]);
  Positions the item in the rows.

  parameters:
    $start - number
    $end - number (optional, default: value from $start)



  EXAMPLE FOR USE:
  ================
  <component-root>
    <div class="grid-container">
      <div class="grid-item1">...</div>
      <div class="grid-item2">...</div>
      <div class="grid-item3 grid-sub-container">
        <div class="grid-sub-item1">...</div>
        <div class="grid-sub-item2">...</div>
      </div>
    </div>
  </component-root>

  .grid-container {
    @include grid-root-container(2);
  }

  .grid-item1 {
    @include grid-item(sideedgeLeft, sideedgeRight);
    @include grid-item-rows(1);
    @include from-width($m) {
      @include grid-item-ie-sideedge-desktop(sideedgeLeft, sideedgeRight);
    }
  }

  .grid-item2 {
    @include grid-item(1, 4);
    @include grid-item-rows(2);
  }

  .grid-item3 {
    @include grid-item(5, 12);
    @include grid-item-rows(2);
  }

  .grid-sub-container {
    @include grid-sub-container(8);
  }

  .grid-sub-item1 {
    @include grid-item(1, 2, true);
    @include grid-item-rows(1);
  }

  .grid-sub-item2 {
    @include grid-item(3, 8, true);
    @include grid-item-rows(1);
  }

 */



// GRID SETTINGS

$mobileSideedge: 6vw;
$mobileColumns: 6;

$desktopSideedge: 40px;
$desktopColumns: 12;
$minWidthForDesktop: $m;

$gridGap: 0.76336vw;
$maxContentWidth: 1280px;


// PUBLIC MIXINS

@mixin grid-root-container($rows:1) {
  max-width: $maxContentWidth;
  margin-left: auto;
  margin-right: auto;
  @include grid();
  -ms-grid-columns: $mobileSideedge legacy-repeat(($mobileColumns - 1), 1fr $gridGap) 1fr $mobileSideedge;
  grid-template-columns: [sideedge-left] $mobileSideedge repeat(($mobileColumns - 1), [col-start] 1fr [col-end] $gridGap) [col-start] 1fr [col-end] $mobileSideedge [sideedge-right];
  -ms-grid-rows: legacy-repeat($rows, auto);
  grid-template-rows: repeat($rows, auto);

  @include from-width($minWidthForDesktop) {
    -ms-grid-columns: $desktopSideedge legacy-repeat(($desktopColumns - 1), 1fr $gridGap) 1fr $desktopSideedge;
    grid-template-columns: [sideedge-left] $desktopSideedge repeat(($desktopColumns - 1), [col-start] 1fr [col-end] $gridGap) [col-start] 1fr [col-end] $desktopSideedge [sideedge-right];
  }
}

@mixin grid-sub-container($cols, $rows:1) {
  @include grid();
  -ms-grid-columns: legacy-repeat(($cols - 1), 1fr $gridGap) 1fr;
  grid-template-columns: repeat(($cols - 1), [col-start] 1fr [col-end] $gridGap) [col-start] 1fr [col-end];
  -ms-grid-rows: legacy-repeat($rows, auto);
  grid-template-rows: repeat($rows, auto);
}

@mixin grid-item($start, $end, $isInSubContainer: false) {
  @include legacy-grid-item($start, $end, $isInSubContainer);

  $outputStart: col-start $start;
  $outputEnd: col-end $end;

  @if ($start == sideedgeLeft) {
    $outputStart: sideedge-left;
  }

  @if $end == sideedgeRight {
    $outputEnd: sideedge-right;
  }

  // Fix for FF layout issues
  // eg. TKGC-757
  width: 100%;

  grid-column: $outputStart / $outputEnd;
}

@mixin grid-item-rows($start, $end:$start) {
  $span: $end - $start + 1;
  @include  legacy-grid-item-rows($start, $span);
  grid-row: $start / span $span;
}

@mixin grid-item-ie-sideedge-desktop($start, $end) {
  @include legacy-grid-item-sideedge($start, $end, 1, $desktopColumns);
}



// PRIVATE FUNCTIONS AND MIXINS

// don't use "public"
@function legacy-repeat($repeats, $content) {
  @return unquote("(#{$content})[#{$repeats}]")
}

// don't use "public"
@mixin grid() {
  display: -ms-grid;
  display: grid;
}

// don't use "public"
@mixin legacy-grid-item($start, $end, $isInSubContainer: false) {
  $sideedgeOffset: 1;

  @if ($isInSubContainer) {
    $sideedgeOffset: 0;
  }

  @include legacy-grid-item-sideedge($start, $end, $sideedgeOffset, $mobileColumns);
}


@mixin legacy-grid-item-sideedge($start, $end, $sideedgeOffset, $baseColumns) {
  $sideedgeAdditional: 0;

  @if ($start == sideedgeLeft) {
    $sideedgeOffset: 0;
    $sideedgeAdditional: $sideedgeAdditional + 1;
    $start: 1;
  }

  @if $end == sideedgeRight {
    $sideedgeAdditional: $sideedgeAdditional + 1;
    $end: $baseColumns;
  }

  $outputStart: $sideedgeOffset + $start + $start - 1;
  $outputSpan: ($end - $start + 1) + ($end - $start) + $sideedgeAdditional;

  -ms-grid-column: $outputStart;
  -ms-grid-column-span: $outputSpan;
}

// don't use "public"
@mixin legacy-grid-item-rows($start, $span) {
  -ms-grid-row: $start;
  -ms-grid-row-span: $span;
}
