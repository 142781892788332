@font-face {
  font-weight: 400;
  src: url('../../node_modules/tk-assets/src/fonts/SoletoTK_W_Lt.woff') format('woff');
  font-family: 'SoletoTK';
}

@font-face {
  font-weight: 500;
  src: url('../../node_modules/tk-assets/src/fonts/SoletoTK_W_Rg.woff') format('woff');
  font-family: 'SoletoTK';
}

@font-face {
  font-weight: 700;
  src: url('../../node_modules/tk-assets/src/fonts/SoletoTK_W_Md.woff') format('woff');
  font-family: 'SoletoTK';
}

@font-face {
  font-weight: 500;
  src: url('../../node_modules/tk-assets/src/fonts/SoletoTKHeadline_W_Lt.woff') format('woff');
  font-family: 'SoletoTKHeadline';
}
