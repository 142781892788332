/*
These styles override the default styles provided by Angular Material v17.
With upgrades to Angular Material v18 or higher, changes may be necessary.
*/

/* hides the standard extend arrow */
.mat-mdc-select > div.mat-mdc-select-trigger > div.mat-mdc-select-arrow-wrapper {
  display: none;
}

.mat-mdc-select > div.mat-mdc-select-trigger {
  padding: 5px 0;
}

/* use full available space */
.mat-mdc-select > div.mat-mdc-select-trigger {
  height: 100%;
}

.mat-mdc-select-value-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

/* aligns placeholder and selection text */
div.mat-mdc-select-value {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
}

/*select option style */
.mat-mdc-option > span.mdc-list-item__primary-text {
  color: var(--black);
  font-weight: 500;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  > span.mdc-list-item__primary-text {
  color: var(--black);
}

/* select - overlay */
div.cdk-overlay-pane {
  margin-top: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border-radius: 5px;
  background-color: white;
  overflow: hidden;
}

div.mat-mdc-select-panel.mdc-menu-surface.mdc-menu-surface--open {
  padding: 5px;
}

/* multi select option - styling and highlighting of unselected and selected optionsTimeSlotType */
.mat-mdc-option {
  margin-bottom: 5px;
  border: 1px solid transparent;
  border-radius: 5px;
  min-height: 35px;

  &:last-child {
    margin-bottom: 0;
  }

  /* multi selected option */
  &.mdc-list-item--selected {
    background-color: var(--light-blue);
  }
}

/* multi select option - on hover */
.mat-mdc-option.mdc-list-item.mat-mdc-option-multiple:hover:not(.mdc-list-item--disabled) {
  background-color: var(--light-grey);
}

/* multi select option - on focus */
.mat-mdc-option.mdc-list-item.mat-mdc-option-multiple.mat-mdc-option-active {
  background-color: var(--light-grey);
}

/* multi select option - selected and on focus */
.mat-mdc-option.mdc-list-item.mat-mdc-option-multiple.mdc-list-item--selected.mat-mdc-option-active {
  background-color: var(--light-grey);
}

/* multi select - unselected multi checkbox*/
.mat-mdc-option.mdc-list-item.mat-mdc-option-multiple:not(.mdc-list-item--selected)
  > .mat-pseudo-checkbox.mat-mdc-option-pseudo-checkbox {
  border-color: var(--dark-grey);
}

/* multi select - selected multi checkbox*/
.mat-mdc-option.mdc-list-item.mat-mdc-option-multiple.mdc-list-item--selected
  > .mat-pseudo-checkbox.mat-mdc-option-pseudo-checkbox.mat-pseudo-checkbox-checked {
  background-color: var(--blue);
  color: white;
}

/* single select - option*/
.mat-mdc-option.mdc-list-item {
  min-height: 35px;
}

/* single select option - on hover */
.mat-mdc-option.mdc-list-item:hover:not(.mdc-list-item--disabled) {
  background-color: var(--light-grey);
}

/* single select option - on focus */
.mat-mdc-option.mdc-list-item.mat-mdc-option-active {
  background-color: var(--light-grey) !important;
}

/* single select - selected*/
.mat-mdc-option.mdc-list-item.mdc-list-item--selected {
  color: var(--light-blue) !important;
}

/* single select option - selected and on focus */
.mat-mdc-option.mdc-list-item.mdc-list-item--selected.mat-mdc-option-active {
  background-color: var(--light-blue) !important;
}

/* single select - checkbox*/
.mat-mdc-option.mdc-list-item.mdc-list-item--selected
  > .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
  background-color: transparent;
  color: var(--blue);
}
