@import "../node_modules/tk-sass-common/src/basics.scss";

// FONTS
@font-face {
  font-family: "SoletoTK";
  src: url("../node_modules/tk-assets/src/fonts/SoletoTK_W_Lt.woff") format('woff');
  font-weight: 400;
}

@font-face {
  font-family: "SoletoTK";
  src: url("../node_modules/tk-assets/src/fonts/SoletoTK_W_Md.woff") format('woff');
  font-weight: 700;
}

@font-face {
  font-family: "SoletoTKHeadline";
  src: url("../node_modules/tk-assets/src/fonts/SoletoTKHeadline_W_Lt.woff") format('woff');
  font-weight: 400;
}

html {
  box-sizing: border-box;
  font-family: 'SoletoTK', sans-serif;
}

*, ::before, ::after {
  box-sizing: inherit;
  font-family: inherit;
}

body {
  background-color: $color-grey-10;
  margin: 0;
}

.grid-container {
  @include grid-root-container();
}

.spinner-container {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.load-more-button-container {
  margin: 15px;
  text-align: center;
}

.important-container {
  background-color: $color-grey-10;
  margin-bottom: 35px;

  &--listing {
    padding: 35px 0 0;
    margin: 0;
  }
}

::ng-deep {
  .mat-snack-bar-container.tgc-snack-bar {
    background-color: #fff;
    border-radius: 0;
    color: $color-anthracite-100;
    margin-top: 90px;

    &--success {
      border-left: 8px solid $color-petrol-70;

      .mat-button-wrapper {
        color: $color-petrol-70;
      }
    }

    &--error {
      border-left: 8px solid $color-red-100;

      .mat-button-wrapper {
        color: $color-red-100;
      }
    }
  }
}

.no-results-container {
  text-align: center;
}

.tag {
  background-color: $color-grey-10;
  border: none;
  color: $color-anthracite-100;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 10px;
  transition: all 300ms ease-in-out;
  word-spacing: 0.16rem;
}
