.mat-mdc-checkbox-checked > .mdc-form-field > .mdc-checkbox > div.mdc-checkbox__background {
  border-color: var(--blue) !important;
  background-color: var(--blue) !important;
}

.mdc-checkbox .mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background {
  opacity: 0.6;
  border-color: var(--dark-grey) !important;
  background-color: var(--dark-grey) !important;
}
