span.mdc-tab__content > span.mdc-tab__text-label {
  color: var(--black);
  font-weight: 500;
  font-size: var(--font-size-large);
}

.mat-mdc-tab-body-wrapper {
  margin-top: 20px;
}

div.mdc-tab--active > span.mdc-tab__content > span.mdc-tab__text-label {
  color: var(--blue);
  font-weight: 500;
}

div.mdc-tab--active:focus > span.mdc-tab__content > span.mdc-tab__text-label {
  color: var(--blue);
  font-weight: 500;
}

/* Border bottom active tab */
div.mdc-tab--active
  > span.mdc-tab-indicator
  > span.mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
  border-color: var(--blue);
}

/* Border bottom active and focused tab */
div.mdc-tab--active:focus
  > span.mdc-tab-indicator
  > span.mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
  border-color: var(--blue);
}
