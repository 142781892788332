/**

  Mixins for public use:

  - fontHeadline() // without own size, e.g. for quotation marks in quote component
  - fontHeadline1()
  - fontHeadline2()
  - fontHeadline3()
  - fontHeadline4()
  - fontHeadline5()
  - fontHeadline6()

  - fontTextL()
  - fontTextM()
  - fontTextS()
  - fontTextXS()
  - fontForm()
  - fontLabel()

  - fontStrong() // without own size, e.g. a strong word in a text
  - fontStrongL()
  - fontStrongM()
  - fontStrongS()
  - fontStrongXS()
  - fontStrongForm()
  - fontStrongLabel()

 */

$fontHeadline: SoletoTKHeadline, Arial, Helvetica, sans-serif;
$fontText: SoletoTK, Arial, Helvetica, sans-serif;
$fontColor: $color-anthracite-100;

$lineHeightFactor: 1.5;
$wordSpacingFactor: 0.16;

$px12: 0.75rem;
$px14: 0.875rem;
$px15: 0.9375rem;
$px16: 1rem;
$px17: 1.0625rem;
$px18: 1.125rem;
$px20: 1.25rem;
$px21: 1.3125rem;
$px22: 1.375rem;
$px23: 1.4375rem;
$px24: 1.5rem;
$px26: 1.625rem;
$px28: 1.75rem;
$px30: 1.875rem;
$px32: 2rem;
$px34: 2.125rem;
$px35: 2.1875rem;
$px45: 2.8125rem;


// don't use "public"
@mixin _fontDef($size) {
  font-size: $size;
  line-height: $size * $lineHeightFactor;
  word-spacing: $size * $wordSpacingFactor;
}

// don't use "public"
@mixin _fontFamilyTkHeadline() {
  font-family: $fontHeadline;
  font-weight: 400;
  color: $fontColor;
}

// don't use "public"
@mixin _fontFamilyTkLight() {
  font-family: $fontText;
  font-weight: 400;
  color: $fontColor;
}

// don't use "public"
@mixin _fontFamilyTkMedium() {
  font-family: $fontText;
  font-weight: 700;
  color: $fontColor;
}

// don't use "public"
@mixin _textSizeL() {
  @include _fontDef($px17);
  @include from-width($l) {
    @include _fontDef($px18);
  }
}

// don't use "public"
@mixin _textSizeM() {
  @include _fontDef($px15);
  @include from-width($l) {
    @include _fontDef($px16);
  }
}

// don't use "public"
@mixin _textSizeS() {
  @include _fontDef($px14);
}

// don't use "public"
@mixin _textSizeXS() {
  @include _fontDef($px12);
}

// don't use "public"
@mixin _textSizeForm() {
  @include _fontDef($px16);
}

// don't use "public"
@mixin _textSizeLabel() {
  @include _fontDef($px14);
  @include from-width($l) {
    @include _fontDef($px16);
  }
}


// PUBLIC MIXINS
@mixin fontHeadline() {
  @include _fontFamilyTkHeadline();
}

@mixin fontHeadline1() {
  @include _fontFamilyTkHeadline();
  @include _fontDef($px26);
  @include from-width($l) {
    @include _fontDef($px35);
  }
  @include from-width($xl) {
    @include _fontDef($px45);
  }
}

@mixin fontHeadline2() {
  @include _fontFamilyTkHeadline();
    @include _fontDef($px23);
  @include from-width($l) {
    @include _fontDef($px32);
  }
  @include from-width($xl) {
    @include _fontDef($px34);
  }
}

@mixin fontHeadline3() {
  @include _fontFamilyTkHeadline();
  @include _fontDef($px23);
  @include from-width($l) {
    @include _fontDef($px28);
  }
  @include from-width($xl) {
    @include _fontDef($px30);
  }
}

@mixin fontHeadline4() {
  @include _fontFamilyTkHeadline();
  @include _fontDef($px21);
  @include from-width($l) {
    @include _fontDef($px23);
  }
  @include from-width($xl) {
    @include _fontDef($px26);
  }
}

@mixin fontHeadline5() {
  @include _fontFamilyTkHeadline();
  @include _fontDef($px17);
  @include from-width($l) {
    @include _fontDef($px23);
  }
  @include from-width($xl) {
    @include _fontDef($px24);
  }
}

@mixin fontHeadline6() {
  @include _fontFamilyTkHeadline();
  @include _fontDef($px17);
  @include from-width($l) {
    @include _fontDef($px20);
  }
  @include from-width($xl) {
    @include _fontDef($px22);
  }
}

@mixin fontTextL() {
  @include _fontFamilyTkLight();
  @include _textSizeL();
}

@mixin fontTextM() {
  @include _fontFamilyTkLight();
  @include _textSizeM();
}

@mixin fontTextS() {
  @include _fontFamilyTkLight();
  @include _textSizeS();
}

@mixin fontTextXS() {
  @include _fontFamilyTkLight();
  @include _textSizeXS();
}

@mixin fontForm() {
  @include _fontFamilyTkLight();
  @include _textSizeForm();
}

@mixin fontLabel() {
  @include _fontFamilyTkLight();
  @include _textSizeLabel();
}

@mixin fontStrong() {
  @include _fontFamilyTkMedium();
}

@mixin fontStrongL() {
  @include _fontFamilyTkMedium();
  @include _textSizeL();
}

@mixin fontStrongM() {
  @include _fontFamilyTkMedium();
  @include _textSizeM();
}

@mixin fontStrongS() {
  @include _fontFamilyTkMedium();
  @include _textSizeS();
}

@mixin fontStrongXS() {
  @include _fontFamilyTkMedium();
  @include _textSizeXS();
}

@mixin fontStrongForm() {
  @include _fontFamilyTkMedium();
  @include _textSizeForm();
}

@mixin fontStrongLabel() {
  @include _fontFamilyTkMedium();
  @include _textSizeLabel();
}


