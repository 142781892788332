/* Import and integrate custom Angular Material TGC theme*/
@use '@angular/material' as mat;
@import 'styles/angular-material/theme';

@include mat.all-component-themes($tgc-theme);
@include mat.all-component-bases($tgc-theme);
@include mat.all-component-typographies($tgc-typography);

/*-------------------------------------------------------------------------------*/

/* Import styles which override the standard angular material styling*/
@import './styles/angular-material/global-style-overriding/dialog';
@import './styles/angular-material/global-style-overriding/general';
@import './styles/angular-material/global-style-overriding/input-checkbox';
@import './styles/angular-material/global-style-overriding/input-date';
@import './styles/angular-material/global-style-overriding/input-text';
@import './styles/angular-material/global-style-overriding/select';
@import './styles/angular-material/global-style-overriding/expansion';
@import './styles/angular-material/global-style-overriding/tab';

/*-------------------------------------------------------------------------------*/

/* Import pattern-lib  styling*/
@import './app/pattern-library/styles/basics';

/*-------------------------------------------------------------------------------*/

/* Import global app styling*/
@import './styles/color-scheme';
@import './styles/layout';
@import './styles/buttons';
@import './styles/font-import.scss';
@import './styles/essentials.scss';

/*-------------------------------------------------------------------------------*/

/* Import tailwind classes*/
@tailwind base;
@tailwind components;
@tailwind utilities;

/*-------------------------------------------------------------------------------*/

* {
  font-family: SoletoTK, sans-serif;
}

:root {
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 2rem;
  --font-size-base: 0.875rem;
  --font-size-large: 1rem;
  --font-size-small: 0.75rem;
  --font-size-xlarge: 1.125rem;
  --font-size-xsmall: 0.625rem;
  --app-container-width: 1100px;
}

form {
  padding: 0 2px;
}

.grid-container {
  @include grid-root-container();
}
