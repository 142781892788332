/*
These styles override the default styles provided by Angular Material v17.
With upgrades to Angular Material v18 or higher, changes may be necessary.
*/

div.cdk-overlay-container {
  z-index: 2000;
}

.mat-mdc-dialog-title.mdc-dialog__title {
  margin: 0;
  padding: 0;
}

.mat-mdc-dialog-container .mat-mdc-dialog-title {
  font-weight: 800 !important;
}

.mat-mdc-dialog-component-host .mat-mdc-dialog-content.mdc-dialog__content {
  margin-top: 2px;
  margin-bottom: 20px;
  padding: 0 2px !important;

  &::before {
    display: block;
    height: 20px;
    content: '';
  }
}

.cdk-overlay-backdrop.cdk-overlay-dark-backdrop.cdk-overlay-backdrop-showing {
  backdrop-filter: blur(2px);
  background-color: rgba(49, 49, 49, 0.7);
}

div.cdk-overlay-pane.mat-mdc-dialog-panel {
  background-color: transparent;
}

div.mat-mdc-dialog-surface.mdc-dialog__surface {
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border-radius: 10px;
  background-color: white;
  padding: 20px 25px;
}

.mat-mdc-dialog-content.mdc-dialog__content {
  padding: 0 !important;
}

.mat-mdc-dialog-title.mdc-dialog__title.header__text {
  margin: 0;
  padding: 0;
  text-align: center;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}
