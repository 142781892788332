.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  &--flex-start {
    justify-content: flex-start;
  }

  &--center {
    justify-content: center;
  }

  &--flex-end {
    justify-content: flex-end;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--space-arround {
    justify-content: space-around;
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
