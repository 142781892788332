@import "breakpoints";
@import "color";

@mixin component-margin() {
  display: block;
  margin-bottom: 35px;

  @include from-width($m) {
    margin-bottom: 50px;
  }
}

@mixin component-margin-top() {
  display: block;
  margin-top: 35px;

  @include from-width($m) {
    margin-top: 50px;
  }
}

// @todo: when all occurences of `@extend .component-margin;` are gone remove this below
.component-margin {
  @include component-margin();
}

@mixin keyboard-focus-outline() {
  outline: 2px solid $color-keyboard-accessibility-outline-color;
}

@mixin has-keyboard-focus() {
  .has-keyboard-focus:focus {
    @include keyboard-focus-outline();
  }
}


