.mat-expansion-panel-header.mat-expanded {
  background-color: var(--light-blue);
}

span.mat-content {
  line-height: 25px;
}

.mat-expansion-panel-header-title {
  display: flex !important;
  flex-direction: row;
  width: 100px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
