/*
These styles override the default styles provided by Angular Material v17.
With upgrades to Angular Material v18 or higher, changes may be necessary.
*/

/* form elements -hides error row under form element */
.mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
  display: none;
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 5px !important;
  font-weight: 500;
  letter-spacing: 0;
}

/* form field - sets width */
mat-form-field {
  width: 100%;
}

/* form field - limits size */
.mat-mdc-form-field-infix {
  border: none;
  background-color: white;
  padding: 0 !important;
  width: 90px !important; // reduce min supported with of the component (180px by default)
  min-height: 0 !important;
}

/* form field - general look */
.mat-mdc-text-field-wrapper {
  border: 1px solid var(--dark-grey) !important;
  border-radius: 5px !important;
  padding: 0 !important;
}

/* hides ripple line under form element*/
.mdc-line-ripple {
  display: none;
}

/* provides same disabled and focused styles across all elements*/
.mdc-text-field {
  &--disabled {
    opacity: 0.8;
  }

  &--focused {
    transition: 0.1s ease-in-out !important;
    box-shadow: 0 0 0 2px rgba(10, 109, 255, 0.3) !important;
    border: 1px solid var(--blue) !important;
  }
}

.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--disabled {
  background-color: var(--light-grey);
}
