html {
  box-sizing: border-box;
  font-family: SoletoTK, sans-serif;
}

*,
::before,
::after {
  box-sizing: inherit;
  font-family: inherit;
}

body {
  margin: 0;
  overflow-y: scroll;
}

textarea,
select,
input {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

ul.square {
  margin-left: 1em;
  list-style-type: square;
}

fieldset {
  border: none;
  padding: 0;
}

.truncate-ellipses {
  cursor: pointer;
  text-decoration: underline;
}

.grid-root-container {
  @include grid-root-container();
}

.standard-grid-item {
  @include grid-item(1, 6);
  @include grid-item-rows(1);
  @include from-width($m) {
    @include grid-item(1, 12);
  }
  @include from-width($l) {
    @include grid-item(2, 8);
  }
}

@include has-keyboard-focus();

button {
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
}

.aria-invisible {
  visibility: hidden;
}

.show-for-print {
  display: none !important;
}

.mobile-only {
  @include from-width($m) {
    display: none;
  }
}

.desktop-only {
  display: none;
  @include from-width($m) {
    display: unset;
  }
}

@media print {
  .hide-for-print {
    display: none !important;
  }

  .show-for-print {
    display: block !important;
  }
}
