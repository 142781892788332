/*
These styles override the default styles provided by Angular Material v17.
With upgrades to Angular Material v18 or higher, changes may be necessary.
*/

/* input field styling */
input.mat-mdc-input-element.mat-mdc-form-field-input-control.mdc-text-field__input.cdk-text-field-autofill-monitored {
  color: var(--black);
  font-weight: 500;
}

/* input field styling */
.mat-mdc-input-element.mat-mdc-form-field-textarea-control.mat-mdc-form-field-input-control.mdc-text-field__input {
  color: var(--black);
  font-weight: 500;
  line-height: 120%;
}
