$color-black: #000000;
$color-white: #ffffff;


/**
* ANTHRACITE
*/
$color-anthracite-110: #292927;
$color-anthracite-100: #454542;
$color-anthracite-90: #565653;
$color-anthracite-80: #696967;
$color-anthracite-70: #727270;
$color-anthracite-60: #8d8d8c;
$color-anthracite-50: #a09f9e;
$color-anthracite-40: #b1b1af;
$color-anthracite-30: #c3c3c2;
$color-anthracite-20: #d5d5d4;
$color-anthracite-10: #ececec;
$color-anthracite-5:  #f5f5f5;



/**
* NIGHTBLUE
*/
$color-nightblue-100: #004466;
$color-nightblue-90: #185574;
$color-nightblue-80: #326884;
$color-nightblue-70: #4a7a91;
$color-nightblue-60: #648da1;
$color-nightblue-50: #7c9eaf;
$color-nightblue-40: #95b0be;
$color-nightblue-30: #aec2cd;
$color-nightblue-20: #ccd9e0;
$color-nightblue-20-transparent: rgba(204, 217, 224, .95);
$color-nightblue-10: #e6ecf0;
$color-nightblue-10-transparent: rgba(230, 236, 240, .95);


/**
* PETROL
*/
$color-petrol-100: #006f8a;
$color-petrol-90: #1a7d95;
$color-petrol-80: #328ba0;
$color-petrol-70: #4a98ab;
$color-petrol-60: #66a8b8;
$color-petrol-50: #7cb4c1;
$color-petrol-40: #95c1cc;
$color-petrol-30: #aecfd7;
$color-petrol-20: #c7dde3;
$color-petrol-10: #e6f0f3;


/**
* YELLOW
*/
$color-yellow-100: #f6f399;
$color-yellow-100-transparent: rgba(246, 243, 153, .95);
$color-yellow-80: #f7f4ac;
$color-yellow-60: #f8f6c0;
$color-yellow-30: #f8f7dc;
$color-yellow-10: #f9f8ef;
$color-yellow-member: #e8e000;


/**
* MK-YELLOW
*/
$mk-color-yellow-100: #f8f5c6;
$mk-color-yellow-100-transparent: rgba(248, 245, 198, .95);


/**
* GREY
*/
$color-grey-100: #dad6d1;
$color-grey-80: #e1deda;
$color-grey-60: #eceae8;
$color-grey-30: #f5f2ef;
$color-grey-10: #f9f7f5;


/**
* RED
*/
$color-red-100: #d01d45;
$color-red-90: #d43357;
$color-red-80: #d94a6a;
$color-red-70: #de607c;
$color-red-60: #e3778f;
$color-red-50: #e78ea2;
$color-red-40: #eca5b5;
$color-red-30: #f0bbc7;
$color-red-20: #f6d2da;
$color-red-10: #fae8ec;


/**
* LILAC / VIOLET
*/
$color-lilac-100: #813f90;
$color-lilac-90: #8c519a;
$color-lilac-80: #9964a5;
$color-lilac-70: #a476af;
$color-lilac-60: #b18aba;
$color-lilac-50: #bd9cc4;
$color-lilac-40: #c9aecf;
$color-lilac-30: #d5c1d9;
$color-lilac-20: #e1d4e4;
$color-lilac-10: #ede6ee;


/**
* KEYBOARD ACCESSIBILITY
*/
$color-keyboard-accessibility-outline-color: #009ee3;
$text-color-keyboard-accessibility: #1a7d95;


// TODO: cleanup after color refactoring
// ANTHRACITE
//$color-anthracite01: $color-anthracite-110;
//$color-anthracite02: $color-anthracite-100;
//$color-anthracite05: $color-anthracite-70;
//$color-anthracite06: $color-anthracite-50;
//$color-anthracite07: $color-anthracite-10;
//$color-anthracite08: $color-anthracite-20;
//$color-anthracite10: $color-anthracite-30;
//$color-anthracite11: $color-anthracite-5;

// GREY
//$color-grey01: $color-grey-100;
//$color-grey02: $color-grey-30;
//$color-grey03: $color-grey-10;
//$color-grey04: $color-grey-60;

// YELLOW
//$color-yellow08: $color-yellow-100;
//$color-yellow08-transparent: $color-yellow-100-transparent;

// PETROL
//$color-petrol01: $color-petrol-100;
//$color-petrol03: $color-petrol-60;
//$color-petrol05: $color-petrol-10;
//$color-petrol06: $color-petrol-60;

// NIGHT BLUE
//$color-nightblue: $color-nightblue-100;
//$color-nightblue02: $color-nightblue-20;
//$color-nightblue02-transparent: $color-nightblue-20-transparent;
//$color-nightblue10: $color-nightblue-10;
//$color-nightblue10-transparent: $color-nightblue-10-transparent;

// VIOLET
//$color-violet: $color-lilac-100;

// RED
//$color-red01: $color-red-100; // e.g. error messages
//$color-red02: $color-red-100; // e.g. error messages hover-state

// <----- deprecated colors

// THEMES COLORS

// Gesundheitscoach
$color-main-gc: $color-nightblue-10;
$color-main-gc-transparent: $color-nightblue-10-transparent;

// Leben mit Krebs
$color-main-lmk: $color-nightblue-20;
$color-main-lmk-transparent: $color-nightblue-20-transparent;

// Medienkompetenz
$color-main-mk: $mk-color-yellow-100;
$color-main-mk-transparent: $mk-color-yellow-100-transparent;
